import "./App.scss";
import Navigation from "./components/Pages/Navigation";
import Footer from "./components/Pages/Footer";

function App() {
  return (
    <>
      <Navigation />

      <Footer />
    </>
  );
}

export default App;

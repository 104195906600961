import React from "react";
import logoImg from "../image/logo.png";
import Gallery from "./card";

const Vision = () => {
  return (
    <>
      <div className="vision-row">
        <div>
          <div
            className="vision-container"
            data-aos="zoom-in"
            data-aos-once="true"
            data-aos-duration="1500"
          >
            <img src={logoImg} alt="prasoon logo" className="logoft" />
            <p className="vision-logo-name">PRASOON</p>
          </div>

          <div
            className="box"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-duration="2000"
            data-aos-delay="500"
          >
            <div className="row">
              <div className="head">
                {" "}
                <img src={logoImg} className="vision-icon" alt="" /> Our Vision
              </div>
            </div>
            <p className="para">
              {" "}
              Development of the society in which everyone (especially poor,
              weaker and marginalized section of the society) can live a life of
              dignity enjoying all human rights.
            </p>
          </div>

          <div
            className="box1"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-duration="2500"
            data-aos-delay="500"
          >
            <div className="row ">
              <div className="head">
                {" "}
                <img src={logoImg} className="vision-icon" alt="" />
                Our Mission
              </div>
            </div>
            <p className="para" style={{ textAlign: "justify" }}>
              {" "}
              To integrate and enhance capacities of the poor marginalized
              section of the society to increase self-confidence,
              self-dependence and power of decision making, to avail equal
              opportunities and then by their equal participation in the
              development process and to ensure the establishment of the society
              of our dreams.
            </p>
          </div>
          <div
            className="box"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="300"
            data-aos-duration="2000"
          >
            <div className="row ">
              <div className="head">
                {" "}
                <img src={logoImg} className="vision-icon" alt="" />
                Purpose
              </div>
            </div>
            <p className="para">
              {" "}
              People from marginalized community still need awareness on their
              Educational, Health, Nutrition and other socio-economic
              development. Since inception, PRASOON is basically working to
              uplift the marginalized section of society through various
              grassroots level interventions for their dignity and identity at
              the crises time, mainstreaming them and their holistic
              development. The major focuses are on Tribal and Dalit communities
              (SCs), Tribal (ST) for protecting their gender equality and
              strengthening the process of democratic decentralization through
              developing community leadership and promoting local self
              governances at the grassroots to avail the constitutional rights.
              Livelihood, Health Awareness, Educational development of target
              group, Protection of child Rights, Water and sanitation and
              displacement are the main areas of intervention.
            </p>
          </div>
        </div>
      </div>
      <Gallery />
    </>
  );
};

export default Vision;

import Carousel from "react-bootstrap/Carousel";
import Img from "../image/Carousel/Carousel1.jpg";
import Img1 from "../image/Carousel/Carousel2.jpg";
import Img2 from "../image/Carousel/Carousel3.jpg";
import Img3 from "../image/Carousel/Carousel4.jpg";

function Carousels() {
  return (
    <Carousel slide={true}>
      <Carousel.Item>
        <img
          className="d-block w-100 carousel"
          src={Img1}
          alt="Prasoon-2.jpg"
        />

        <Carousel.Caption>
          <h3>Vaccination Camp</h3>
          <p>Covid-19 vaccination drive conducted.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100 carousel" src={Img} alt="Prasoon-1.jpg" />
        <Carousel.Caption>
          <h3>Campaign Against Child Labour.</h3>
          <p>Conference on Child Labour for controlling the child labour.</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={Img2} alt="logo.png" className="d-block w-100 carousel" />
        <Carousel.Caption>
          <h3>Health Campaign</h3>
          <p>Campaign conducted for the medical checkup.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={Img3} alt="logo.png" className="d-block w-100 carousel" />
        <Carousel.Caption>
          <h3>Agriculture Awareness</h3>
          <p>Campaign for Organic Farming.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carousels;

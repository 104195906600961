import React, { useState } from "react";
import logoImg from "../image/Prasoon logo.png";
import Carousels from "./Carousel";
import Shimmer from "./Shimmer";

function Home() {
  const [isLoaded, setIsLoaded] = useState(false);

  setTimeout(() => {
    setIsLoaded(true);
  }, 500);

  return (
    <>
      {!isLoaded && <Shimmer />}{" "}
      {isLoaded && (
        <>
          <Carousels />
        </>
      )}
      <div className="marque">
        <marquee behavior="" direction="">
          <ul className="lists">
            <li>Women empowerment through advocacy.</li>
            <li>Women Sanitation.</li>
            <li>Rural and Urban Development</li>
            <li>Child Development and Malnutrition</li>
          </ul>
        </marquee>
      </div>
      <div className="row w-100 my-3">
        <div
          className="col-md-6 py-2 "
          data-aos="fade-in"
          data-aos-delay="500"
          data-aos-once="true"
          data-aos-duration="2000"
        >
          <h1 align="center" className="head">
            "PRASOON"
          </h1>
          <p align="justify" className="para">
            Prasoon is a membership based organization registered under the
            societies registration act, 1984. In the undivided state of Madhya
            Pradesh, the organization was involved in grass root intervention
            for betterment of marginalized communities by strengthening their
            livelihood resources, campaigning & advocating for their rights over
            natural resources and food security, capacity enhancement for
            improving their quality life, encouraging cooperative initiatives,
            leadership development and self-reliance. The operational area of
            Prasoon was especially in tribal dominated area of 3 districts in
            Madhya Pradesh and 5 districts of Chhattisgarh.
          </p>
        </div>
        <div
          className="col-md-6  py-2 list-para "
          data-aos="fade-in"
          data-aos-delay="500"
          data-aos-once="true"
          data-aos-duration="2000"
        >
          <h1>The Thematic areas are..</h1>
          <ul className="para-list ">
            <li>Child Protection</li>
            <li>Child Education</li>
            <li>Health (TB,Malnutrition&Silicosis)</li>
            <li>
              Creating awareness among Women & Adolescent girls on Health &
              Hygiene etc.
            </li>
            <li>Nutrition</li>
            <li>Environment (Plantation Drive)</li>
            <li>Livelihood</li>
            <li>Women Empowerment</li>
            <li>Farmers Colletivisation</li>
            <li>Strengthen of local Self-Governance</li>
            <li>Water conservation and Organic Farming.</li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="row w-100 my-4">
        <div
          className="col-md-6 d-flex justify-content-center py-5 logo-box"
          data-aos="zoom-in-up"
          data-aos-once="true"
          data-aos-duration="2000"
        >
          <img src={logoImg} alt="logo.png" className="info-logo" />
        </div>
        <div
          className="col-md-6 py-2  history-container"
          data-aos="zoom-in-up"
          data-aos-once="true"
          data-aos-duration="2000"
        >
          {" "}
          <h1 align="center" className="head">
            History
          </h1>
          <p className="para" align="justify">
            Prasoon is a membership based organization registered under the
            societies registration act, 1984. In the undivided state of Madhya
            Pradesh, the organization was involved in grass root intervention
            for betterment of marginalized communities by strengthening their
            livelihood resources, campaigning & advocating for their rights over
            natural resources and food security, capacity enhancement for
            improving their quality life, encouraging cooperative initiatives,
            leadership development and self-reliance. The operational area of
            Prasoon was especially in tribal dominated area of 3 districts in
            Madhya Pradesh and 5 districts of Chhattisgarh.
          </p>
        </div>
      </div>
      <hr />
    </>
  );
}

export default Home;

import React from "react";
import Carousel from "react-grid-carousel";
import { Images } from "../../consts";

const Gallery = () => {
  return (
    <>
      <div className="py-5">
        <h1 align="center" className=" carousel-head">
          Newspaper Coverage{" "}
        </h1>
        <Carousel cols={4} rows={1} gap={50} loop>
          {Images.map((image, index) => (
            <Carousel.Item key={index}>
              <div
                className="carousel-box"
                data-aos="fade-in"
                data-aos-once="true"
                data-aos-duration="2000"
              >
                <img className="carousel-img" src={image} key={index} />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default Gallery;

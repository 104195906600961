import React from "react";
import { Image } from "primereact/image";
import { images19 } from "../../consts";

const Gallery2019 = () => {
  return (
    <>
      <h1 align="center" className="carousel-head mt-2">
        Gallery2019
      </h1>
      <div className="cards">
        {images19.map((image, index) => (
          <Image
            data-aos="zoom-in"
            data-aos-once="true"
            data-aos-duration="2000"
            key={index}
            src={image}
            className="img"
            alt={`Image ${index + 1}`}
            width="350"
            preview
          />
        ))}
      </div>
    </>
  );
};

export default Gallery2019;

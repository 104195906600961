import React from "react";
import logoImg from "../image/logo.png";
import Nav from "react-bootstrap/Nav";

const Footer = () => {
  return (
    <>
      <div>
        <section id="footer">
          <div className="container">
            <div className="row text-center text-xs-center text-sm-left text-md-left">
              <div className="col-xs-12 col-sm-4 col-md-4">
                <h3> Quick links </h3>
                <ul className="list-unstyled quick-links">
                  <li>
                    <Nav.Link className="list" href="/">
                      <i className="fa fa-angle-double-right"> </i>
                      Home
                    </Nav.Link>
                  </li>

                  <li>
                    <Nav.Link className="list" href="/vision">
                      <i className="fa fa-angle-double-right"> </i> Vision{" "}
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link className="list" href="/gallery21">
                      <i className="fa fa-angle-double-right"> </i> Gallery{" "}
                    </Nav.Link>
                  </li>
                  <li>
                    <Nav.Link className="list" href="/contact">
                      <i className="fa fa-angle-double-right"> </i> Contact{" "}
                    </Nav.Link>{" "}
                  </li>
                </ul>
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 address">
                <h4 className="fs-4">OUR ADDRESS </h4>
                <img
                  src={logoImg}
                  alt="prasoon logo"
                  className="logoft"
                  style={{ margin: ".3em " }}
                />
                <p className="logo-name">PRASOON</p>
                <div>
                  <p className=" fs-6">
                    Gandhi Bhawan Campus <br />
                    Shyamla Hills, Bhopal-462002 <br />
                    Madhya Pradesh
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4">
                <h3 className="text-white">
                  {" "}
                  Registered Under Societies <br /> Registration Act, <br />{" "}
                  1973.{" "}
                </h3>
                <ul className="list-unstyled quick-links text-white reg-list">
                  <li>Registration No. : 13714, Dated : 23.05.1984</li>

                  <li> Registered under FCR Act. 1976 </li>
                  <li>No. : 0631004, Dated : 21.01.1985</li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                <ul className=" social ">
                  <li className="list-inline-item">
                    {" "}
                    <a href="https://www.facebook.com/prasoon.orgnizationbhopal">
                      {" "}
                      <i className="fa fa-facebook"> </i>{" "}
                    </a>{" "}
                  </li>
                  <li className="list-inline-item">
                    {" "}
                    <a href="https://www.youtube.com/@prasoonngom.p4983">
                      {" "}
                      <i className="fa fa-youtube"> </i>{" "}
                    </a>{" "}
                  </li>
                  <li className="list-inline-item">
                    {" "}
                    <a href="https://www.instagram.com/prasoon__ganjbasoda/">
                      {" "}
                      <i className="fa fa-instagram"> </i>{" "}
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row p-4 ">
              <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                <p className="fs-6">
                  <a className="ml-2" href="#" target="_blank">
                    2023 | &copy; All rights Reserved , Prasoon NGO | Bhopal
                  </a>
                </p>
              </div>
              <hr />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;

import car1 from "./components/image/papercuttings/carousel.jpg";
import car2 from "./components/image/papercuttings/carousel1.jpg";
import car3 from "./components/image/papercuttings/carousel3.jpg";
import car4 from "./components/image/papercuttings/carousel4.jpg";
import car5 from "./components/image/papercuttings/carousel5.jpg";
import car6 from "./components/image/papercuttings/carousel6.jpg";
import car7 from "./components/image/papercuttings/carousel7.jpg";
import car8 from "./components/image/papercuttings/carousel8.jpg";
import car9 from "./components/image/papercuttings/carousel9.jpeg";
import car10 from "./components/image/papercuttings/carousel10.jpg";
import car11 from "./components/image/papercuttings/carousel11.jpg";
import car12 from "./components/image/papercuttings/carousel12.jpg";

// Gallery 2021
import img1 from "./components/prasoon 2021/img1.jpeg";
import img2 from "./components/prasoon 2021/img2.jpeg";
import img3 from "./components/prasoon 2021/img3.JPG";
import img4 from "./components/prasoon 2021/img4.JPG";
import img5 from "./components/prasoon 2021/img5.jpg";
import img6 from "./components/prasoon 2021/img6.jpg";
import img7 from "./components/prasoon 2021/img7.jpg";
import img8 from "./components/prasoon 2021/img8.JPG";
import img9 from "./components/prasoon 2021/img9.jpeg";
import img10 from "./components/prasoon 2021/img10.jpg";
import img11 from "./components/prasoon 2021/img11.jpg";
import img12 from "./components/prasoon 2021/img12.jpg";
import img13 from "./components/prasoon 2021/img13.jpeg";
import img14 from "./components/prasoon 2021/img14.JPG";
import img15 from "./components/prasoon 2021/img15.jpg";
import img16 from "./components/prasoon 2021/img16.jpg";
import img17 from "./components/prasoon 2021/img17.jpg";
import img18 from "./components/prasoon 2021/img18.JPG";
import img19 from "./components/prasoon 2021/img19.JPG";
import img20 from "./components/prasoon 2021/img20.jpg";
import img21 from "./components/prasoon 2021/img21.jpg";
import img22 from "./components/prasoon 2021/img22.jpg";
import img23 from "./components/prasoon 2021/img23.JPG";
import img24 from "./components/prasoon 2021/img24.jpg";
import img25 from "./components/prasoon 2021/img25.jpg";
import img26 from "./components/prasoon 2021/img26.jpg";
import img27 from "./components/prasoon 2021/img27.jpg";
import img28 from "./components/prasoon 2021/img28.jpg";
import img29 from "./components/prasoon 2021/img29.jpeg";
import img30 from "./components/prasoon 2021/img30.jpeg";
import img31 from "./components/prasoon 2021/img31.jpeg";
import img32 from "./components/prasoon 2021/img32.jpeg";
import img33 from "./components/prasoon 2021/img33.JPG";
import img34 from "./components/prasoon 2021/img34.JPG";
import img35 from "./components/prasoon 2021/img35.jpg";
import img36 from "./components/prasoon 2021/img36.jpg";
import img37 from "./components/prasoon 2021/img37.jpg";
import img38 from "./components/prasoon 2021/img38.jpeg";
import img39 from "./components/prasoon 2021/img39.jpg";
import img40 from "./components/prasoon 2021/img40.jpg";
import img41 from "./components/prasoon 2021/img41.jpg";
import img42 from "./components/prasoon 2021/img42.jpg";
import img43 from "./components/prasoon 2021/img43.JPG";
import img44 from "./components/prasoon 2021/img44.jpg";
import img45 from "./components/prasoon 2021/img45.jpg";
import img46 from "./components/prasoon 2021/img46.JPG";
import img47 from "./components/prasoon 2021/img47.JPG";
import img48 from "./components/prasoon 2021/img48.JPG";
import img49 from "./components/prasoon 2021/img49.JPG";
import img50 from "./components/prasoon 2021/img50.jpg";
import img51 from "./components/prasoon 2021/img51.jpg";
import img52 from "./components/prasoon 2021/img52.jpg";
import img53 from "./components/prasoon 2021/img53.jpg";
import img54 from "./components/prasoon 2021/img54.JPG";
import img55 from "./components/prasoon 2021/img55.jpg";
import img56 from "./components/prasoon 2021/img56.jpg";
import img57 from "./components/prasoon 2021/img57.jpg";
import img58 from "./components/prasoon 2021/img58.jpg";
import img59 from "./components/prasoon 2021/img59.jpg";
import img60 from "./components/prasoon 2021/img60.jpg";
import img61 from "./components/prasoon 2021/img61.jpg";
import img62 from "./components/prasoon 2021/img62.jpg";
import img63 from "./components/prasoon 2021/img63.jpg";
import img64 from "./components/prasoon 2021/img64.jpg";
import img65 from "./components/prasoon 2021/img65.jpg";
import img66 from "./components/prasoon 2021/img66.jpg";
import img67 from "./components/prasoon 2021/img67.jpeg";
import img68 from "./components/prasoon 2021/img68.jpeg";
import img69 from "./components/prasoon 2021/img69.jpeg";
import img70 from "./components/prasoon 2021/img70.jpeg";
// Gallery 2020
import Img1 from "./components/prasoon 2020/img1.jpg";
import Img2 from "./components/prasoon 2020/img2.JPG";
import Img3 from "./components/prasoon 2020/img3.JPG";
import Img4 from "./components/prasoon 2020/img4.JPG";
import Img5 from "./components/prasoon 2020/img5.JPG";
import Img6 from "./components/prasoon 2020/img6.JPG";
import Img7 from "./components/prasoon 2020/img7.JPG";
import Img8 from "./components/prasoon 2020/img8.jpg";
import Img9 from "./components/prasoon 2020/img9.jpg";
import Img10 from "./components/prasoon 2020/img10.jpg";
import Img11 from "./components/prasoon 2020/img11.jpg";
import Img12 from "./components/prasoon 2020/img12.jpg";
import Img13 from "./components/prasoon 2020/img13.jpeg";
import Img14 from "./components/prasoon 2020/img14.jpeg";
import Img15 from "./components/prasoon 2020/img15.jpeg";
import Img16 from "./components/prasoon 2020/img16.jpeg";
import Img17 from "./components/prasoon 2020/img17.jpeg";
import Img18 from "./components/prasoon 2020/img18.jpeg";
import Img19 from "./components/prasoon 2020/img19.jpg";
import Img20 from "./components/prasoon 2020/img20.jpg";
import Img21 from "./components/prasoon 2020/img21.jpeg";
import Img22 from "./components/prasoon 2020/img22.jpeg";
import Img23 from "./components/prasoon 2020/img23.jpeg";
import Img24 from "./components/prasoon 2020/img24.jpeg";
import Img25 from "./components/prasoon 2020/img25.jpeg";
import Img26 from "./components/prasoon 2020/img26.jpeg";
import Img27 from "./components/prasoon 2020/img27.jpeg";
import Img28 from "./components/prasoon 2020/img28.jpeg";
import Img29 from "./components/prasoon 2020/img29.jpg";
import Img30 from "./components/prasoon 2020/img30.jpg";
import Img31 from "./components/prasoon 2020/img31.jpg";
import Img32 from "./components/prasoon 2020/img32.jpg";
import Img33 from "./components/prasoon 2020/img33.jpg";
import Img34 from "./components/prasoon 2020/img34.jpg";
import Img35 from "./components/prasoon 2020/img35.jpg";
import Img36 from "./components/prasoon 2020/img36.jpeg";
import Img37 from "./components/prasoon 2020/img37.jpg";
import Img38 from "./components/prasoon 2020/img38.jpg";
import Img39 from "./components/prasoon 2020/img39.jpg";
import Img40 from "./components/prasoon 2020/img40.jpg";

// Gallery 2019
import imgs1 from "./components/prasoon 2019/img1.jpeg";
import imgs2 from "./components/prasoon 2019/img2.jpeg";
import imgs3 from "./components/prasoon 2019/img3.jpeg";
import imgs4 from "./components/prasoon 2019/img4.jpeg";
import imgs5 from "./components/prasoon 2019/img5.jpeg";
import imgs6 from "./components/prasoon 2019/img6.JPG";
import imgs7 from "./components/prasoon 2019/img7.jpg";
import imgs8 from "./components/prasoon 2019/img8.JPG";
import imgs9 from "./components/prasoon 2019/img9.JPG";
import imgs10 from "./components//prasoon 2019/img10.JPG";
import imgs11 from "./components//prasoon 2019/img11.JPG";
import imgs12 from "./components//prasoon 2019/img12.jpg";
import imgs13 from "./components//prasoon 2019/img13.jpg";
import imgs14 from "./components//prasoon 2019/img14.jpg";
import imgs15 from "./components//prasoon 2019/img15.jpg";
import imgs16 from "./components//prasoon 2019/img16.jpg";
import imgs17 from "./components//prasoon 2019/img17.JPG";
import imgs18 from "./components//prasoon 2019/img18.JPG";
import imgs19 from "./components//prasoon 2019/img19.JPG";
import imgs20 from "./components//prasoon 2019/img20.JPG";
import imgs21 from "./components//prasoon 2019/img21.JPG";
import imgs22 from "./components//prasoon 2019/img22.JPG";
import imgs23 from "./components//prasoon 2019/img23.JPG";
import imgs24 from "./components//prasoon 2019/img24.JPG";
import imgs25 from "./components//prasoon 2019/img25.JPG";
import imgs26 from "./components//prasoon 2019/img26.JPG";
import imgs27 from "./components//prasoon 2019/img27.JPG";
import imgs28 from "./components//prasoon 2019/img28.JPG";
import imgs29 from "./components//prasoon 2019/img29.jpg";
import imgs30 from "./components//prasoon 2019/img30.jpg";
import imgs31 from "./components//prasoon 2019/img31.jpg";
import imgs32 from "./components//prasoon 2019/img32.jpg";
import imgs33 from "./components//prasoon 2019/img33.jpg";
import imgs34 from "./components//prasoon 2019/img34.jpg";
import imgs35 from "./components//prasoon 2019/img35.jpg";
import imgs36 from "./components//prasoon 2019/img36.jpg";
import imgs37 from "./components//prasoon 2019/img37.jpeg";
import imgs38 from "./components//prasoon 2019/img38.jpeg";
import imgs39 from "./components//prasoon 2019/img39.jpeg";
import imgs40 from "./components//prasoon 2019/img40.JPG";
import imgs41 from "./components//prasoon 2019/img41.jpg";
import imgs42 from "./components//prasoon 2019/img42.jpg";
import imgs43 from "./components//prasoon 2019/img43.jpeg";
import imgs44 from "./components//prasoon 2019/img44.jpg";
import imgs45 from "./components//prasoon 2019/img45.jpg";
import imgs46 from "./components//prasoon 2019/img46.jpg";
import imgs47 from "./components//prasoon 2019/img47.jpg";
import imgs48 from "./components//prasoon 2019/img48.jpg";
import imgs49 from "./components//prasoon 2019/img49.jpg";
import imgs50 from "./components//prasoon 2019/img50.jpg";
import imgs51 from "./components//prasoon 2019/img51.jpg";
import imgs52 from "./components//prasoon 2019/img52.jpg";
import imgs53 from "./components//prasoon 2019/img53.jpg";
import imgs54 from "./components//prasoon 2019/img54.jpeg";
import imgs55 from "./components//prasoon 2019/img55.jpeg";
import imgs56 from "./components//prasoon 2019/img56.jpeg";
import imgs57 from "./components//prasoon 2019/img57.jpeg";
import imgs58 from "./components//prasoon 2019/img58.jpg";

export const Images = [
  car1,
  car2,
  car3,
  car4,
  car5,
  car6,
  car7,
  car8,
  car9,
  car10,
  car11,
  car12,
];

export const images21 = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img44,
  img43,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img56,
  img54,
  img55,
  img57,
  img58,
  img59,
  img60,
  img61,
  img62,
  img63,
  img64,
  img65,
  img66,
  img67,
  img68,
  img69,
  img70,
];

export const images20 = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Img17,
  Img18,
  Img19,
  Img20,
  Img21,
  Img22,
  Img23,
  Img24,
  Img25,
  Img26,
  Img27,
  Img28,
  Img29,
  Img30,
  Img31,
  Img32,
  Img33,
  Img34,
  Img35,
  Img36,
  Img37,
  Img38,
  Img39,
  Img40,
];

export const images19 = [
  imgs1,
  imgs2,
  imgs5,
  imgs10,
  imgs6,
  imgs8,
  imgs9,
  imgs4,

  imgs11,
  imgs12,
  imgs13,
  imgs14,
  imgs15,
  imgs16,
  imgs17,
  imgs18,
  imgs19,
  imgs20,
  imgs21,
  imgs22,
  imgs23,
  imgs24,
  imgs25,
  imgs26,
  imgs27,
  imgs28,
  imgs29,
  imgs30,
  imgs31,
  imgs32,
  imgs33,
  imgs34,
  imgs35,
  imgs36,
  imgs37,
  imgs38,
  imgs39,
  imgs40,
  imgs41,
  imgs42,
  imgs44,
  imgs43,
  imgs7,
  imgs3,
  imgs45,
  imgs46,
  imgs47,
  imgs48,
  imgs49,
  imgs50,
  imgs51,
  imgs52,
  imgs53,
  imgs56,
  imgs54,
  imgs55,
  imgs57,
  imgs58,
];

import logoImg from "../image/Prasoon logo.png";
import React from "react";

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="w-100 contact row">
        <div
          className="col-md-6 contact-logo"
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-duration="1000"
        >
          <img src={logoImg} alt="logo.png" className="info-logo-1" />
        </div>
        <div
          className="col-md-6 contact-info"
          data-aos="fade-in"
          data-aos-once="true"
          data-aos-duration="2000"
        >
          <h1 align="center" className="carousel-head-1 mt-2">
            SUDHIR KUMAR BHARGAV
          </h1>
          <p align="center" className="text-black fs-3">
            {"(SECRETARY)"}
          </p>

          <div className="address">
            <h2>&nbsp;&nbsp; ADDRESS:-</h2>
            <h1> Head Office</h1>
            <h3 className="address-info">
              <i className="fa fa-angle-double-right"> </i> &nbsp;Gandhi Bhawan
              Campus, Shyamla Hills, Bhopal-462002 (M.P.)
            </h3>
            <hr />
            <h1>Branch Office</h1>
            <h3 className="address-info mb-2">
              <i className="fa fa-angle-double-right"> </i> &nbsp;Gram Bhandaria
              Road, Sant Vinobabhawe, Ward No. 7, Khandwa, (M.P.)
            </h3>
            <h3 className="address-info">
              <i className="fa fa-angle-double-right"> </i>&nbsp; Ward No. 2,
              Laxmi Ganj, Khara Kunwa wali Gali 246, Nayk Ji ka Makan (Ganj
              Basoda Dist. Vidisha)
            </h3>
            <hr />
            <h1 className="email">
              <span className="material-symbols-outlined mr-5">mail</span> Email
            </h1>
            <h3 className="address-info mb-2">
              <i className="fa fa-angle-double-right"> </i>&nbsp;
              prasoon.org@gmail.com
            </h3>
            <h3 className="address-info">
              <i className="fa fa-angle-double-right"> </i>&nbsp;
              skbhargav16@gmail.com
            </h3>
            <hr />
            <h1 className="contact-no">
              {" "}
              <span className="material-symbols-outlined mr-2">
                phone_iphone
              </span>{" "}
              Contact Number
            </h1>
            <h3 className="address-info mb-2">
              <i className="fa fa-angle-double-right"> </i>&nbsp; 9826275262,
              7974554653
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

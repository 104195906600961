import React from "react";

const Shimmer = () => {
  return (
    <>
      <div className="mb-4">
        <div className="shimmer my-3"></div>
      </div>
    </>
  );
};

export default Shimmer;

import React from "react";
import { Image } from "primereact/image";
import { images20 } from "../../consts";

const Gallery2020 = () => {
  return (
    <>
      <h1 align="center" className=" mt-2 carousel-head">
        Gallery2020
      </h1>
      <div className="cards">
        {images20.map((image, index) => (
          <Image
          
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-duration="2000"
            key={index}
            src={image}
            className="img"
            alt={`Image ${index + 1}`}
            width="350"
            preview
          />
        ))}
      </div>
    </>
  );
};

export default Gallery2020;

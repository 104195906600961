import React from "react";
import { Routes, Route } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logoImg from "../image/logo.png";
import Home from "./Home";
import Vision from "./Vision";
import Gallery2021 from "./Gallery2021";
import Gallery2020 from "./Gallery2020";
import Gallery2019 from "./Gallery2019";
import Contact from "./Contact";

function Navigation() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" id="navigation">
        <div className="container-fluid pl-2">
          <Navbar.Brand href="/" className="d-flex">
            <img src={logoImg} alt="logo.png" className="logo" />
            <h1>PRASOON</h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav ">
            <Nav className="me-auto left-nav">
              <Nav.Link className="list" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="list" href="/vision">
                Vision
              </Nav.Link>

              <NavDropdown title="Gallery" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/gallery21" className="drpdwn">
                  Gallery 2021
                </NavDropdown.Item>
                <NavDropdown.Item href="/gallery20" className="drpdwn">
                  Gallery 2020{" "}
                </NavDropdown.Item>
                <NavDropdown.Item href="/gallery19" className="drpdwn">
                  Gallery 2019
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="list" href="/contact">
                Contact
              </Nav.Link>
            </Nav>
            <Nav className="right-nav">
              <Nav.Link href="#deets" className="icons-row">
                <ul className="list-unstyled list-inline social ">
                  <li className="list-inline-item">
                    {" "}
                    <a href="https://www.facebook.com/prasoon.orgnizationbhopal">
                      {" "}
                      <i className="fa fa-facebook"> </i>{" "}
                    </a>{" "}
                  </li>
                  <li className="list-inline-item">
                    {" "}
                    <a href="https://www.instagram.com/prasoon__ganjbasoda/">
                      {" "}
                      <i className="fa fa-instagram"> </i>{" "}
                    </a>{" "}
                  </li>

                  <li className="list-inline-item">
                    {" "}
                    <a href="https://www.youtube.com/@prasoonngom.p4983">
                      {" "}
                      <i className="fa fa-youtube"> </i>{" "}
                    </a>{" "}
                  </li>
                </ul>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/vision" element={<Vision />} />
          <Route exact path="/gallery21" element={<Gallery2021 />} />
          <Route exact path="/gallery20" element={<Gallery2020 />} />
          <Route exact path="/gallery19" element={<Gallery2019 />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route path="/index.html" element={<Home />} />
        </Routes>
      </div>
    </>
  );
}

export default Navigation;

// import React, { useState } from "react";
// import { Link, Routes, Route, useLocation } from "react-router-dom";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
// import logoImg from "./components/image/logo.png";
// import Home from "./Home";
// import Vision from "./Vision";
// import Gallery2021 from "./Gallery2021";
// import Gallery2020 from "./Gallery2020";
// import Gallery2019 from "./Gallery2019";
// import Contact from "./Contact";

// function Navigation() {
//   const [expanded, setExpanded] = useState(false);
//   const location = useLocation();

//   const handleNavItemClick = () => {
//     setExpanded(false);
//   };

//   const handleMenuToggle = () => {
//     setExpanded(!expanded);
//   };

//   return (
//     <>
//       <Navbar
//         collapseOnSelect
//         expand="lg"
//         id="navigation"
//         expanded={expanded}
//         onSelect={handleNavItemClick}
//       >
//         <div className="container-fluid pl-2">
//           <Navbar.Brand href="/" className="d-flex">
//             <img src={logoImg} alt="logo.png" className="logo" />
//             <h1>PRASOON</h1>
//           </Navbar.Brand>
//           <Navbar.Toggle
//             aria-controls="responsive-navbar-nav"
//             onClick={handleMenuToggle}
//           />
//           <Navbar.Collapse id="responsive-navbar-nav">
//             <Nav className="me-auto left-nav">
//               <Nav.Link
//                 className="list"
//                 as={Link}
//                 to="/"
//                 eventKey="/"
//                 onClick={handleNavItemClick}
//               >
//                 Home
//               </Nav.Link>
//               <Nav.Link
//                 className="list"
//                 as={Link}
//                 to="/vision"
//                 eventKey="/vision"
//                 onClick={handleNavItemClick}
//               >
//                 Vision
//               </Nav.Link>

//               <NavDropdown
//                 title="Gallery"
//                 id="collasible-nav-dropdown"
//                 align="end"
//               >
//                 <NavDropdown.Item
//                   as={Link}
//                   to="/gallery21"
//                   eventKey="/gallery21"
//                   onClick={handleNavItemClick}
//                 >
//                   Gallery 2021
//                 </NavDropdown.Item>
//                 <NavDropdown.Item
//                   as={Link}
//                   to="/gallery20"
//                   eventKey="/gallery20"
//                   onClick={handleNavItemClick}
//                 >
//                   Gallery 2020
//                 </NavDropdown.Item>
//                 <NavDropdown.Item
//                   as={Link}
//                   to="/gallery19"
//                   eventKey="/gallery19"
//                   onClick={handleNavItemClick}
//                 >
//                   Gallery 2019
//                 </NavDropdown.Item>
//               </NavDropdown>
//               <Nav.Link
//                 className="list"
//                 as={Link}
//                 to="/contact"
//                 eventKey="/contact"
//                 onClick={handleNavItemClick}
//               >
//                 Contact
//               </Nav.Link>
//             </Nav>
//             <Nav className="right-nav">
//               <Nav.Link href="#deets" className="icons-row">
//                 <ul className="list-unstyled list-inline social">
//                   <li className="list-inline-item">
//                     <a href="https://www.facebook.com/prasoon.orgnizationbhopal">
//                       <i className="fa fa-facebook"></i>
//                     </a>
//                   </li>
//                    <li className="list-inline-item">
//                     <a href="https://www.instagram.com/prasoon__ganjbasoda/">
//                       <i className="fa fa-instagram"></i>
//                     </a>
//                   </li>

//                   <li className="list-inline-item">
//                     <a href="https://www.youtube.com/@prasoonngom.p4983">
//                       <i className="fa fa-youtube"></i>
//                     </a>
//                   </li>
//                 </ul>
//               </Nav.Link>
//             </Nav>
//           </Navbar.Collapse>
//         </div>
//       </Navbar>

//       <div>
//         <Routes>
//           <Route exact path="/" element={<Home />} />
//           <Route exact path="/vision" element={<Vision />} />
//           <Route exact path="/gallery21" element={<Gallery2021 />} />
//           <Route exact path="/gallery20" element={<Gallery2020 />} />
//           <Route exact path="/gallery19" element={<Gallery2019 />} />
//           <Route exact path="/contact" element={<Contact />} />
//           <Route path="/index.html" element={<Home />} />
//         </Routes>
//       </div>
//     </>
//   );
// }

// export default Navigation;
